.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 999999;
}

.ant-select-selection__rendered {
  height: 45px;
  font-size: 15px;
  border-radius: 0;
  line-height: 40px !important;
}

.ant-select-selection {
  min-height: 45px !important;
  border-radius: 0 !important;
}

.ant-select-selection-selected-value,
.ant-select-selection__placeholder,
.ant-select-tree-title {
  font-size: 15px;
}

.ant-popover {
  z-index: 0 !important;
}

.ant-notification,
.ant-select-dropdown {
  z-index: 9999999 !important;
}

.ant-select-dropdown {
  font-size: 16px !important;
}

.ant-select-dropdown-menu {
  max-height: 203px !important; /* 5 1/2 lines */
}

.ant-tooltip-inner {
  color: #5d5d5d !important;
  background-color: rgba(255, 255, 255, 1) !important;
}

button[readonly] {
  background: #ccc;
  cursor: not-allowed;
}

.ant-dropdown.ant-dropdown-placement-bottomCenter {
  -webkit-transform-origin: top center;
      -ms-transform-origin: top center;
          transform-origin: top center;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
          transform-origin: top left;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  -webkit-transform-origin: top right;
      -ms-transform-origin: top right;
          transform-origin: top right;
}

.ant-dropdown.ant-dropdown-placement-topCenter {
  -webkit-transform-origin: bottom center;
      -ms-transform-origin: bottom center;
          transform-origin: bottom center;
}

.ant-dropdown.ant-dropdown-placement-topLeft {
  -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
          transform-origin: bottom left;
}

.ant-dropdown.ant-dropdown-placement-topRight {
  -webkit-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
          transform-origin: bottom right;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active {
  -webkit-animation-name: ant-dropdown-zoom-in !important;
          animation-name: ant-dropdown-zoom-in !important;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: ant-dropdown-zoom-out !important;
          animation-name: ant-dropdown-zoom-out !important;
}

.ant-popover-placement-bottom {
  z-index: 9999999 !important;
}

@-webkit-keyframes ant-dropdown-zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes ant-dropdown-zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes ant-dropdown-zoom-out {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes ant-dropdown-zoom-out {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}