@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-hairline.woff2') format('woff2'), url('~fonts/lato-hairline.woff') format('woff');
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-hairline-italic.woff2') format('woff2'), url('~fonts/lato-hairline-italic.woff') format('woff');
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-thin.woff2') format('woff2'), url('~fonts/lato-thin.woff') format('woff');
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-thin-italic.woff2') format('woff2'), url('~fonts/lato-thin-italic.woff') format('woff');
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-light.woff2') format('woff2'), url('~fonts/lato-light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-light-italic.woff2') format('woff2'), url('~fonts/lato-light-italic.woff') format('woff');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-regular.woff2') format('woff2'), url('~fonts/lato-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-italic.woff2') format('woff2'), url('~fonts/lato-italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-medium.woff2') format('woff2'), url('~fonts/lato-medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-medium-italic.woff2') format('woff2'), url('~fonts/lato-medium-italic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-semibold.woff2') format('woff2'), url('~fonts/lato-semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-semibold-italic.woff2') format('woff2'), url('~fonts/lato-semibold-italic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-bold.woff2') format('woff2'), url('~fonts/lato-bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-bold-italic.woff2') format('woff2'), url('~fonts/lato-bold-italic.woff') format('woff');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-heavy.woff2') format('woff2'), url('~fonts/lato-heavy.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-heavy-italic.woff2') format('woff2'), url('~fonts/lato-heavy-italic.woff') format('woff');
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-black.woff2') format('woff2'), url('~fonts/lato-black.woff') format('woff');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url('~fonts/lato-black-italic.woff2') format('woff2'), url('~fonts/lato-black-italic.woff') format('woff');
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
