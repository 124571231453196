/* Make sure the antd's CSS reset is imported before introducing our own base styles: */
@import '~antd/es/style/index.css';

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  color: #333330 !important;
  font-size: 14px !important;
  font-family: 'Lato' !important;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.fa-angle-times::before {
  content: '\F105\F104';
  letter-spacing: -0.15em;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* Override styles for react sortable tree */
.rst__lineBloc,
.rst__lineChildren,
.rst__absoluteLineBlock,
.rst__lineBlock {
  display: none !important;
}

.rst__rowContents {
  padding: 0px !important;
  border: none;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rst__moveHandle {
  border: none;
  color: gray;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 35px;
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6);
  position: relative;
  top: 11px;
  min-width: 44px;
  left: 5px;
}

.rst__node:first-child {
  border-top: 1px solid #cccccc;
}

.rst__node {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.rst__node:last-child {
  border-bottom: 1px solid #cccccc;
}

.rst__nodeContent {
  right: 0;
}
.rst__rowContents {
  display: -ms-flexbox;
  display: flex;
}
.rst__rowLabel {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  display: -ms-flexbox;
  display: flex;
}
.rst__rowTitle {
  display: block;
  width: 100%;
}
